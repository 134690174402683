<template>
  <div class="order">
    <van-nav-bar
      left-arrow
      @click-left="onClickLeft"
      :right-text="symbol"
      @click-right="onClickRight"
    />
    <van-dropdown-menu active-color="#1989fa" class="dropdownms">
      <van-dropdown-item
        v-model="commandValue"
        :options="commandType"
        @change="commandChange"
      />
    </van-dropdown-menu>
    <van-row
      type="flex"
      class="setVolume"
      v-if="orderType != 2 || (commandValue >= 2 && commandValue <= 5)"
    >
      <van-col span="4" class="volumeChange">
        <van-button type="info" size="mini" plain @click="volumeChange(-0.1)"
          >-0.1</van-button
        >
      </van-col>
      <van-col span="4" class="volumeChange">
        <van-button type="info" size="mini" plain @click="volumeChange(-0.01)"
          >-0.01</van-button
        >
      </van-col>
      <van-col span="8" class="volumeInput">
        <van-field
          v-model="volume"
          type="number"
          input-align="center"
          center
          clearable
        />
      </van-col>
      <van-col span="4" class="volumeChange">
        <van-button type="info" size="mini" plain @click="volumeChange(0.01)"
          >+0.01</van-button
        >
      </van-col>
      <van-col span="4" class="volumeChange">
        <van-button type="info" size="mini" plain @click="volumeChange(0.1)"
          >+0.1</van-button
        >
      </van-col>
    </van-row>

    <van-row
      type="flex"
      justify="center"
      v-if="commandValue >= 2 && commandValue <= 5"
    >
      <van-col span="3"></van-col>
      <van-col span="9" style="vertical-align: middle; padding-top: 8px">
        {{ $t("Mt4ApiTrade.pendingPrice") }}
      </van-col>
      <van-col span="12">
        <van-stepper
          v-model="pendingPrice"
          input-width="90"
          class="stepSLTP"
          :step="stepSLTP"
          min="-1"
          :decimal-length="digits"
          @plus="pricePlus"
          @minus="priceMinus"
        />
      </van-col>
    </van-row>

    <van-row type="flex" justify="center">
      <van-col span="3"></van-col>
      <van-col span="9" style="vertical-align: middle; padding-top: 8px">
        {{ $t("Mt4ApiTrade.stopLoss") }}
      </van-col>
      <van-col span="12">
        <van-stepper
          v-model="sl"
          input-width="90"
          class="stepSLTP"
          :step="stepSLTP"
          min="-1"
          :decimal-length="digits"
          @plus="slPlus"
          :disabled="disabledForSlTp"
          @minus="slMinus"
        />
      </van-col>
    </van-row>
    <van-row type="flex" justify="center">
      <van-col span="3"></van-col>
      <van-col span="9" style="vertical-align: middle; padding-top: 8px">
        {{ $t("Mt4ApiTrade.takeProfit") }}
      </van-col>
      <van-col span="12">
        <van-stepper
          v-model="tp"
          input-width="90"
          class="stepSLTP"
          :step="stepSLTP"
          min="-1"
          :decimal-length="digits"
          @plus="tpPlus"
          :disabled="disabledForSlTp"
          @minus="tpMinus"
        />
      </van-col>
    </van-row>

    <van-row
      type="flex"
      justify="center"
      v-if="commandValue >= 2 && commandValue <= 5"
    >
      <van-col span="3"></van-col>
      <van-col span="9" style="vertical-align: middle; padding-top: 8px">
        {{ $t("Mt4ApiTrade.pendingExpDate") }}
      </van-col>
      <van-col span="12">
        <van-cell
          :value="pendingDate"
          @click="showExpDatePopup"
          class="pendingDate"
        />
        <van-popup
          v-model="showExpDate"
          round
          position="bottom"
          :style="{ height: '50%' }"
        >
          <van-datetime-picker
            v-model="currentDate"
            type="datetime"
            :title="$t('Mt4ApiTrade.pendingExpDate')"
            :min-date="minDate"
            :max-date="maxDate"
            @confirm="confirmExpDate"
            @cancel="cancelExpDate"
            :formatter="formatterDate"
          />
        </van-popup>
      </van-col>
    </van-row>

    <van-row gutter="2" v-if="commandValue <= 1" type="flex" justify="center">
      <van-col span="12" class="priceQuote">
        <van-cell :title="priceBid" />
      </van-col>
      <van-col span="12" class="priceQuote">
        <van-cell :title="priceAsk" />
      </van-col>
    </van-row>
    <van-row gutter="2" v-if="orderType == 0 && commandValue <= 1">
      <van-col span="12">
        <van-button
          :disabled="btnDisabled"
          type="danger"
          block
          @click="openOrder(1)"
          >{{ $t("Mt4ApiTrade.sellByMarket") }}</van-button
        >
      </van-col>
      <van-col span="12">
        <van-button
          :disabled="btnDisabled"
          color="#13b5b1"
          block
          @click="openOrder(0)"
          >{{ $t("Mt4ApiTrade.buyByMarket") }}</van-button
        >
      </van-col>
    </van-row>

    <van-row gutter="2" v-if="orderType == 1 && commandValue <= 1">
      <van-button
        type="danger"
        block
        @click="closeOrder()"
        :disabled="btnDisabled"
        >Close</van-button
      >
    </van-row>
    <van-row gutter="2" v-if="orderType == 2 && commandValue <= 1">
      <van-button
        :disabled="btnDisabled"
        type="danger"
        block
        @click="modifyOrder()"
        >{{ $t("Mt4ApiTrade.modifyOrder") }}</van-button
      >
    </van-row>

    <van-row gutter="2" v-if="commandValue >= 2 && commandValue <= 5">
      <van-button
        :disabled="btnDisabled"
        type="danger"
        block
        @click="pendingOrder()"
        >{{ $t("Mt4ApiTrade.pendingOrder") }}</van-button
      >
    </van-row>
    <van-col span="24" style="margin-bottom: 5px; margin-top: 10px">
      <Layout :title="symbol">
        <div id="update-k-line" class="k-line-chart" />
      </Layout>
    </van-col>
    <van-notice-bar
      wrapable
      :scrollable="false"
      :text="$t('Mt4ApiTrade.tradeWarning')"
    />
  </div>
</template>
<script>
import { dispose, init } from "klinecharts";
    import { Toast, Notify, Dialog} from "vant";
import {
  GetKLineData,
  GetLastPrice,
  NewOrder,
  CloseOrder,
  ModifyOrder,
  PendingOrder,
} from "@/api/mt4Api";
import { floatAdd, floatSub, floatMul, forPrice } from "@/utils/commons";
import Layout from "@/components/mt4/Layout";
import baseConfig from "@/utils/baseConfig";
export default {
  name: "NewOrder",
  components: { Layout },
  data() {
    return {
      symbol: "",
      ticket: 0,
      digits: 0,
      pendingPrice: 0,
      pendingDate: "",
      showExpDate: false,
      orderType: 0, //default 0:open  1:close  2:modify
      userinfo: this.$store.state.mt4userinfo,
      timeOutKline: 1000, //10 s
      wsUrl: baseConfig.socketPath,
      timeout: 1000, //1 s
      timeoutObj: null,
      websocket: null,
      sl: 0,
      stepSLTP: "",
      tp: 0,
      priceAsk: "",
      priceBid: "",
      volume: "0.01",
      maxVolume: 0,
      disabledForSlTp: false,
      btnDisabled: false,
      kLineChart: null,
      timeZone: baseConfig.timeZone,
      theme: baseConfig.kLineTheme,
      klineType: this.$store.state.klineType,
      commandValue: 1,
      commandType: [
        { text: this.$t("Mt4ApiTrade.marketExecution"), value: 1 },
        { text: this.$t("Mt4ApiTrade.buyLimit"), value: 2 },
        { text: this.$t("Mt4ApiTrade.sellLimit"), value: 3 },
        { text: this.$t("Mt4ApiTrade.buyStop"), value: 4 },
        { text: this.$t("Mt4ApiTrade.sellStop"), value: 5 },
      ],

      minDate: new Date(),
      maxDate: new Date(2035, 10, 1),
      currentDate: new Date(),
    };
  },
  watch: {},
  mounted() {
    this.$store.commit("toggleTabbarShow", false);
    this.$store.commit("toggleTabbarCurrentShow", "Mt4");

    let cmd = this.$route.query.cmd;
    if (cmd == undefined) {
      this.commandValue = 1;
    } else {
      this.commandValue = parseInt(cmd);
    }
    //console.log(this.commandValue);
    this.symbol = this.$route.query.symbol;
    this.digits = this.$route.query.digits;
    this.lastPrice();
    var setp = Math.pow(10, -this.digits);
    this.stepSLTP = setp;

    this.orderType = this.$route.query.orderType;
    if (this.commandValue <= 1) {
      this.ticket = this.$route.query.ticket;
      // close order
      if (this.orderType == 1) {
        this.disabledForSlTp = true;
        this.maxVolume = parseInt(this.$route.query.volume);
        console.log(this.maxVolume);
        this.volume = forPrice(floatMul(this.maxVolume, 0.01), 2);
      }
      // modify order
      if (this.orderType == 2) {
        this.sl = this.$route.query.sl;
        this.tp = this.$route.query.tp;
      }
    }
    //console.log(this.ticket);
    this.initChart();
    this.webSocketInit();
  },
  created() {
    this.$store.commit("toggleTabbarShow", false);
  },
  beforeCreate() {},
  beforeDestroy() {
    this.distoryTimer();
  },
  methods: {
    initChart() {
      this.kLineChart = init("update-k-line");
      let param = {
        symbol: this.symbol,
        period: 1,
        space: 600 * 60,
      };

      GetKLineData(param).then((res) => {
        if (res.code !== 200) {
          console.log(this.$t(res.msg));
        } else {
          //console.log(res.msg);
          this.kLineChart.clearData();
          this.kLineChart.setPriceVolumePrecision(parseInt(this.digits), 0);
          this.kLineChart.applyNewData(res.msg);
        }
      });

      const updateData = () => {
        param = {
          symbol: this.symbol,
          period: 1,
          space: 1,
        };
        if (this.timeOutKline > 0) {
          setTimeout(() => {
            GetKLineData(param).then((res) => {
              if (res.code !== 200) {
                console.log(res.msg);
              } else {
                //console.log(res.msg);
                this.kLineChart.updateData(res.msg[0]);
                updateData(this.kLineChart);
              }
            });
          }, this.timeOutKline);
        }
      };
      this.kLineChart.setTimezone(this.timeZone);
      this.kLineChart.setStyleOptions(this.setStylesOptions(this.theme));
      updateData();
    },
    webSocketInit() {
      let _this = this;
      var data = { clientid: _this.userinfo.clientid };
      let snedData = JSON.stringify(data);
      var heartCheck = {
        reset: function () {
          clearInterval(_this.timeoutObj);
          return this;
        },
        start: function () {
          this.timeoutObj = setInterval(function () {
            //if (_this.websocket.readyState == WebSocket.OPEN) {}
            if (_this.timeout > 0) {
              _this.websocket.send(snedData);
            }
          }, _this.timeout);
        },
      };
        if (typeof WebSocket == "undefined") {
            Dialog.alert({
                message: "Does not support socket",
            }).then(() => {
                // on close
            });
        
        return;
      }
      let socketContent = "";
      _this.websocket = new WebSocket(_this.wsUrl);
      _this.websocket.binaryType = "blob";
      _this.websocket.onopen = function () {
        console.log("websocket connection success");
        _this.websocket.send(snedData);
        heartCheck.reset().start();
      };
      _this.websocket.onmessage = function (event) {
        if (event.data instanceof Blob) {
          var reader = new FileReader();
          reader.readAsText(event.data);
          reader.onload = () => {
            socketContent = reader.result;
            var json = JSON.parse(socketContent);
            if (parseInt(json.status) === 200) {
              let price = json.list;
              for (var i = 0; i < price.length; i++) {
                if (price[i].symbol == _this.symbol) {
                  //console.log(price[i]);
                  _this.priceAsk = forPrice(price[i].ask, price[i].digits);
                  _this.priceBid = forPrice(price[i].bid, price[i].digits);
                }
              }
            } else {
              if (parseInt(json.status) === 401) {
                this.$router.push("/LoginMt4");
              } else {
                console.log("no price");
              }
            }
          };
        } else {
          console.log("Result: " + event.data);
        }
      };
      _this.websocket.onerror = function (error) {
        console.log("connection error:" + error.data);
      };
      _this.websocket.onclose = function () {
        console.log("connection closed");
      };
    },
    onClickLeft() {
      this.distoryTimer();
      this.$router.push("/trade");
    },
    successToPositions(msg) {
      const toast = Toast.loading({
        duration: 0,
        forbidClick: true,
        message: msg,
      });

      let second = 2;
      const timer = setInterval(() => {
        second--;
        if (second) {
          toast.message = msg;
        } else {
          clearInterval(timer);
          toast.clear();
          this.$router.push("/trade");
        }
      }, 1000);
    },

    onClickRight() {
      this.$router.push({
        path: "/symbols",
        query: {
          symbol: this.symbol,
          path: "NewOrder",
        },
      });
    },
    distoryTimer() {
      if (this.websocket) {
        this.timeout = 0;
        this.timeoutObj = null;
        this.websocket.close();
      }
      this.timeOutKline = 0;
    },
    beforeRouteLeave(to, from, next) {
      this.distoryTimer();
    },
    lastPrice() {
      GetLastPrice().then((res) => {
        res.msg.AllSymbol.forEach((x) => {
          if (x.symbol == this.symbol) {
            this.priceAsk = x.ask;
            this.priceBid = x.bid;
          }
        });
      });
    },
    openOrder(cmd) {
      this.btnDisabled = true;
      let param = {
        volume: this.volume,
        sl: this.sl,
        tp: this.tp,
        cmd: cmd,
        symbol: this.symbol,
        comment: "",
      };
      NewOrder(param).then((res) => {
        if (res.code == 200) {
          this.successToPositions(this.$t(res.msg));
        } else {
            Dialog.alert({
                message: this.$t(res.msg),
            }).then(() => {
                // on close
            });
          this.btnDisabled = false;
        }
      });
    },
    slPlus() {
      if (this.sl <= this.stepSLTP) {
        this.sl = this.priceBid;
      }
    },
    slMinus() {
      if (this.sl <= this.stepSLTP) {
        this.sl = this.priceBid;
      }
    },
    tpPlus() {
      if (this.tp <= this.stepSLTP) {
        this.tp = this.priceBid;
      }
    },
    tpMinus() {
      if (this.tp <= this.stepSLTP) {
        this.tp = this.priceBid;
      }
    },
    pricePlus() {
      if (this.pendingPrice <= 0) {
        this.pendingPrice = this.priceBid;
      }
    },
    priceMinus() {
      if (this.pendingPrice <= 0) {
        this.pendingPrice = this.priceBid;
      }
    },
    volumeChange(volume) {
      if (volume < 0) {
        let change = floatSub(parseFloat(this.volume), Math.abs(volume));
        if (change <= 0) {
          this.volume = 0.01;
        } else {
          this.volume = forPrice(change, 2);
        }
      } else {
        let change = floatAdd(volume, parseFloat(this.volume));
        this.volume = forPrice(change, 2);
      }
      let currVolume = this.volume * 100;
      //console.log("currVolume:" + currVolume + ",maxVolume:" + this.maxVolume);
      if (this.maxVolume != 0 && parseInt(currVolume) > this.maxVolume) {
        this.btnDisabled = true;
      } else {
        this.btnDisabled = false;
      }
    },
    closeOrder() {
      this.btnDisabled = true;
      let param = {
        ticket: this.ticket,
        volume: this.volume,
        sl: this.sl,
        tp: this.tp,
        symbol: this.symbol,
        comment: "",
      };
      CloseOrder(param).then((res) => {
        if (res.code == 200) {
          this.successToPositions(this.$t(res.msg));
        } else {
            Dialog.alert({
                message: this.$t(res.msg),
            }).then(() => {
                // on close
            });
          this.btnDisabled = false;
        }
      });
    },
    modifyOrder() {
      this.btnDisabled = true;
      let param = {
        ticket: this.ticket,
        sl: this.sl,
        tp: this.tp,
        symbol: this.symbol,
        cmd: this.commandValue,
      };
      ModifyOrder(param).then((res) => {
        if (res.code == 200) {
          this.successToPositions(this.$t(res.msg));
        } else {
            Dialog.alert({
                message: this.$t(res.msg),
            }).then(() => {
                // on close
            });
          this.btnDisabled = false;
        }
      });
    },
    pendingOrder() {
      this.btnDisabled = true;
      let param = {
        volume: this.volume,
        sl: this.sl,
        tp: this.tp,
        cmd: this.commandValue,
        price: this.pendingPrice,
        expiration: this.pendingDate,
        symbol: this.symbol,
        comment: "",
        price: this.pendingPrice,
      };
      PendingOrder(param).then((res) => {
        if (res.code == 200) {
          this.successToPositions(this.$t(res.msg));
        } else {
            Dialog.alert({
                message: this.$t(res.msg),
            }).then(() => {
                // on close
            });
          this.btnDisabled = false;
        }
      });
    },
    setStylesOptions(theme) {
      const textColorDark = baseConfig.textColorDark;
      const gridColorDark = baseConfig.gridColorDark;
      const axisLineColorDark = baseConfig.axisLineColorDark;
      const crossTextBackgroundColorDark =
        baseConfig.crossTextBackgroundColorDark;

      const textColorLight = baseConfig.textColorLight;
      const gridColorLight = baseConfig.gridColorLight;
      const axisLineColorLight = baseConfig.axisLineColorLight;
      const crossTextBackgroundColorLight =
        baseConfig.crossTextBackgroundColorLight;

      const textColor = theme === "dark" ? textColorDark : textColorLight;
      const gridColor = theme === "dark" ? gridColorDark : gridColorLight;
      const axisLineColor =
        theme === "dark" ? axisLineColorDark : axisLineColorLight;
      const crossLineColor =
        theme === "dark" ? axisLineColorDark : axisLineColorLight;
      const crossTextBackgroundColor =
        theme === "dark"
          ? crossTextBackgroundColorDark
          : crossTextBackgroundColorLight;

      if (theme === "dark") {
        document.getElementsByClassName(
          "k-line-chart-container"
        )[0].style.backgroundColor = baseConfig.backgroundColorDark;
      }

      return {
        grid: {
          horizontal: {
            color: gridColor,
          },
          vertical: {
            color: gridColor,
          },
        },
        candle: {
          type: this.klineType, //"candle_solid","area",
          priceMark: {
            high: {
              color: textColor,
            },
            low: {
              color: textColor,
            },
          },
          tooltip: {
            text: {
              color: textColor,
            },
            labels: [
              this.$t("KLineChart.time"),
              this.$t("KLineChart.open"),
              this.$t("KLineChart.close"),
              this.$t("KLineChart.high"),
              this.$t("KLineChart.low"),
              this.$t("KLineChart.volume"),
            ],
          },
        },
        //area: {
        //    lineSize: 1,
        //    lineColor: '#2196F3',
        //    value: 'close',
        //    fillColor: [
        //        {
        //            offset: 0,
        //            color: 'rgba(33, 150, 243, 0.01)'
        //        },
        //        {
        //            offset: 1,
        //            color: 'rgba(33, 150, 243, 0.2)'
        //        }
        //    ]
        //},
        technicalIndicator: {
          tooltip: {
            text: {
              color: textColor,
            },
          },
        },
        xAxis: {
          axisLine: {
            color: axisLineColor,
          },
          tickLine: {
            color: axisLineColor,
          },
          tickText: {
            color: textColor,
          },
        },
        yAxis: {
          axisLine: {
            color: axisLineColor,
          },
          tickLine: {
            color: axisLineColor,
          },
          tickText: {
            color: textColor,
          },
        },
        separator: {
          color: axisLineColor,
        },
        crosshair: {
          horizontal: {
            line: {
              color: crossLineColor,
            },
            text: {
              backgroundColor: crossTextBackgroundColor,
            },
          },
          vertical: {
            line: {
              color: crossLineColor,
            },
            text: {
              backgroundColor: crossTextBackgroundColor,
            },
          },
        },
      };
    },
    commandChange() {
      let command = parseInt(this.commandValue);
      if (command == 1) {
        //market order
        if (this.orderType == 1) {
          //close order
          this.sl = 0;
          this.tp = 0;
          this.disabledForSlTp = true;
        }
      } else if (command >= 2 && command <= 5) {
        //pending order
        this.disabledForSlTp = false;
        if (this.pendingDate == "") {
          this.initPendintDate();
        }
      } else {
        console.log("Undefined CommandType");
      }
    },
    formatterDate(type, value) {
      if (type === "year") {
        return `${value}`;
      } else if (type === "month") {
        return `${value}`;
      } else if (type === "day") {
        return `${value}`;
      } else if (type === "hour") {
        return `${value}`;
      } else if (type === "minute") {
        return `${value}`;
      } else if (type === "second") {
        return `${value}`;
      }
      return value;
    },
    showExpDatePopup() {
      this.showExpDate = true;
    },
    confirmExpDate(val) {
      let year = val.getFullYear();
      let month = val.getMonth() + 1;
      let day = val.getDate();
      let hour = val.getHours();
      let minute = val.getMinutes();
      if (month >= 1 && month <= 9) {
        month = `0${month}`;
      }
      if (day >= 1 && day <= 9) {
        day = `0${day}`;
      }
      if (hour >= 0 && hour <= 9) {
        hour = `0${hour}`;
      }
      if (minute >= 0 && minute <= 9) {
        minute = `0${minute}`;
      }
      this.pendingDate = `${year}-${month}-${day} ${hour}:${minute}`;
      this.showExpDate = false;
    },
    initPendintDate() {
      let val = new Date();
      let year = val.getFullYear();
      let month = val.getMonth() + 1 + 1;
      let day = val.getDate();
      if (month >= 12) {
        year += 1;
        month = 1;
        day = 1;
      }
      let hour = val.getHours();
      let minute = val.getMinutes();
      if (month >= 1 && month <= 9) {
        month = `0${month}`;
      }
      if (day >= 1 && day <= 9) {
        day = `0${day}`;
      }
      if (hour >= 0 && hour <= 9) {
        hour = `0${hour}`;
      }
      if (minute >= 0 && minute <= 9) {
        minute = `0${minute}`;
      }
      this.pendingDate = `${year}-${month}-${day} ${hour}:${minute}`;
      this.currentDate = new Date(year, month, day, hour, minute);
    },
    cancelExpDate() {
      this.showExpDate = false;
    },
  },
  destroyed: function () {
    dispose("update-k-line");
  },
};
</script>



<style>
.order {
  overflow-y: auto;
  background-color: #fff;
}

.order .van-nav-bar {
  background-color: #13b5b1;
}

.volumeChange {
  padding: 5px;
  background-color: #fff;
}

.volumeInput .van-cell {
  padding: 8px 16px;
  background-color: rgb(236, 234, 234);
  border-radius: 10px;
  overflow: hidden;
  line-height: 0;
}

.setVolume .van-button {
  border: none;
  /* background-color: transparent; */
}

.priceQuote {
  text-align: center;
  background-color: #fff;
  padding-top: 0;
}

.order .van-icon.van-icon-arrow-left {
  color: #fff;
}

.order .van-nav-bar__text {
  color: #fff;
}

.priceQuote .van-cell {
  padding-top: 0 !important;
}

.dropdownms .van-dropdown-menu__bar {
  box-shadow: 0 0px 0px rgb(100 101 102 / 12%);
}

.dropdownms {
  border-bottom: 1px solid #e1e1e1;
}

.dropdownms .van-cell {
  padding: 0 5px;
}

.dropdownms .van-cell__value {
  padding-top: 10px;
}

.pendingDate.van-cell {
  background-color: transparent;
}

.order .van-cell__title {
  padding: 10px;
}

.stepSLTP .van-stepper__input {
  background-color: transparent;
}

.stepSLTP button[type="button"] {
  background-color: transparent;
}

.volumeChange .van-button__text {
  color: #13b5b1;
  font-size: 14px;
}

.order .van-row.van-row--flex.van-row--justify-center {
  margin-top: 5px;
  background-color: #f7f7f7;
  border-radius: 16px;
}

.order .van-row.van-row--flex.van-row--justify-center:nth-child(7) {
  margin-bottom: 5px;
}

.pendingDate.van-cell {
  line-height: 10px;
}
</style>